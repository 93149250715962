export enum CreditLabel {
    BANKRUPTCY_INSOLVENCY = "Bankruptcy / Insolvency",
    DEFAULT_PAYMENT = "Default / Missed Payment",
    LAWSUIT_CRIME = "Lawsuits/Crime",
    REGULATION_VIOLATION = "Regulation Violation",
    KEY_PERSON_SCANDAL = "Key Person Scandal",
    ACQUISITION = "Acquisition",
    MERGER = "Merger",
    CREDIT_RATING_UPGRADE = "Credit Rating Upgrade",
    CREDIT_RATING_DOWNGRADE = "Credit Rating Downgrade",
    STAFF_REDUCTION = "Staff Reduction",
    CYBER_RISK = "Cyber Risk",
    PROFIT_INCREASE = "Profit Increase",
    PROFIT_DECREASE = "Profit Decrease",
    STOCK_INCREASE = "Stock Increase",
    STOCK_DECREASE = "Stock Decrease",
    COST_CUTS = "Cost Cuts",
    STOCK_LISTING = "Stock Listing",
    PRODUCT_LAUNCH = "Product Launch",
    PRODUCT_FAILURE = "Product Failure",
    ACTIVE_CUSTOMER_DEVELOPMENT = "Active Customer Development",
    NEGATIVE_CUSTOMER_DEVELOPMENT = "Negative Customer Development",
    BUSINESS_RESTRUCTURE = "Business Restructure",
    CORPORATE_TAX_INCREASE = "Corporate Tax Increase",
    BOYCOTT = "Boycott",
    NEW_LOAN_ISSUED = "New Loan Issued",
    SPINOFF = "Spinoff",
    LOAN_REDUCTION = "Loan Reduction",
    PANDEMIC = "Pandemic",
    EXECUTIVE_RESIGNATION = "Executive Resignation",
    EXECUTIVE_APPOINTMENT = "Executive Appointment",
    DEBT_RESTRUCTURING = "Debt Restructuring",

    //ceo enums are deprecated. using executive instead
    CEO_RESIGNATION = "CEO Resignation",
    CEO_APPOINTMENT = "CEO Appointment",
}
