import { NgModule } from '@angular/core';
import { ChartContainerCardComponent } from './chart-container-card.component';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { BaseCardModule } from '../base-card/base-card.module';
import { CustomTooltipModule } from '../custom-tooltip/custom-tooltip.module';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SentimentScoreChartModule } from '../sentiment-score-chart/sentiment-score-chart.module';
import { EventsCountChartModule } from '../events-count-chart/events-count-chart.module';
import { MatNativeDateModule } from '@angular/material/core';
import { NewsVolumeChartModule } from '../news-volume-chart/news-volume-chart.module';


@NgModule({
    declarations: [
      ChartContainerCardComponent
    ],
    imports: [
      FormsModule,
      CommonModule,
      MatFormFieldModule,
      MatDatepickerModule,
      MatNativeDateModule,
      FontAwesomeModule,
      ToastModule,
      FormsModule,
      BaseCardModule,
      CustomTooltipModule,
      SentimentScoreChartModule,
      EventsCountChartModule,
      NewsVolumeChartModule
    ],
    exports: [
      ChartContainerCardComponent
    ],
    providers: [
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          subscriptSizing: 'dynamic'
        }
      }
    ]
  })
  export class ChartContainerCardModule {}