import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.css']
})
export class BaseCardComponent {

  @Input() contentType: 'card' | 'chart' = 'card';

  @Input() title: string = '';

  @Input() loading: boolean = true;

  @Input() error: boolean = false;

  @Input() errorText: string = ""

  @Input() showRefresh: boolean = false;

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();

  cardStyle = {
    background: 'rgb(250,249,248)',
    height: '180px',
    width: '280px;'
  }

  chartStyle = {
    background: 'white',
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,0.1)',
    borderWidth: '.5px'
  }

  applyStyle(){
    switch(this.contentType){
      case 'card' : return this.cardStyle
      case 'chart' : return this.chartStyle
    }
  }

}
