<div class="news-feed-sidebar-content" [ngStyle]="loading ? { 'pointer-events': 'none'} : {}">

    <div class="news-feed-sidebar-filter-header-container">
        <div class="news-feed-sidebar-filter-title">Filters</div>
        <div class="news-feed-sidebar-filter-reset-all" (click)="resetAllFilters()">Reset All Filters</div>
    </div>

    <div class="news-feed-sidebar-subfilter-container">
        <div class="news-feed-sidebar-subfilter-header-container">
            <div class="news-feed-sidebar-subfilter-title">Events</div>
            <div class="news-feed-sidebar-subfilter-reset" (click)="resetEventFilters(true)">Select All</div>
            <div class="news-feed-sidebar-subfilter-reset" (click)="resetEventFilters(false)">Select None</div>
        </div>
        <div class="news-feed-sidebar-checkbox-container">
            <input class="news-feed-sidebar-checkbox-input" type="checkbox" id="positive-events-checkbox" 
                [(ngModel)]="positiveEventsSelected" (change)="updateFilters()">
            <span class="news-feed-sidebar-checkmark"></span>
            <label class="news-feed-sidebar-checkbox-label" for="positive-events-checkbox">Positive Events</label>
        </div>
        <div class="news-feed-sidebar-checkbox-container">
            <input class="news-feed-sidebar-checkbox-input" type="checkbox" id="negative-events-checkbox" 
                [(ngModel)]="negativeEventsSelected" (change)="updateFilters()">
            <span class="news-feed-sidebar-checkmark"></span>
            <label class="news-feed-sidebar-checkbox-label" for="negative-events-checkbox">Negative Events</label>
        </div>
    </div>

    <div class="news-feed-sidebar-subfilter-container">
        <div class="news-feed-sidebar-subfilter-header-container">
            <div class="news-feed-sidebar-subfilter-title">Categories</div>
            <div class="news-feed-sidebar-subfilter-reset" (click)="resetCategoryFilters(true)">Select All</div>
            <div class="news-feed-sidebar-subfilter-reset" (click)="resetCategoryFilters(false)">Select None</div>
        </div>
        <div class="news-feed-sidebar-category-container">
            <div class="news-feed-sidebar-checkbox-container">
                <input class="news-feed-sidebar-checkbox-input" type="checkbox" [id]="'other-checkbox'" 
                    [(ngModel)]="nonCreditRelevantSelected" (change)="updateFilters()">
                <span class="news-feed-sidebar-checkmark"></span>
                <label class="news-feed-sidebar-checkbox-label" [for]="'other-checkbox'">
                    Other ({{categories.nonCreditRelevantCount}})
                </label>
            </div>
            <ng-template ngFor let-item [ngForOf]="categoryCheckbox" let-i="index" *ngIf="categories && categories.creditLabelFilters.length > 0">
                <div class="news-feed-sidebar-checkbox-container">
                    <input class="news-feed-sidebar-checkbox-input" type="checkbox" [id]="item.category + '-checkbox'" 
                        [(ngModel)]="item.checked" (change)="updateFilters()">
                    <span class="news-feed-sidebar-checkmark"></span>
                    <label class="news-feed-sidebar-checkbox-label" [for]="item.category + '-checkbox'">
                        {{item.category.creditLabel}} ({{item.category.count}})
                    </label>
                </div>
            </ng-template>
        </div>
        
    </div>
    
</div>