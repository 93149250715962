import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ScoresAndEventsRequest } from '../models/ScoresAndEventRequest';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StoriesRequest } from '../models/StoriesRequest';
import { CreditLabel } from '../models/CreditLabel';
import { NewsStoriesResponse } from '../models/NewsStoriesResponse';
import { ScoreEventResponse } from '../models/ScoreEventResponse';
import { ScoreResponse } from '../models/ScoreResponse';
import { ScoresRequest } from '../models/ScoresRequest';
import { NewsEdgeResponse } from '../models/NewsEdgeResponse';

var he = require('he');

@Injectable()
export class CreditSentimentAPIService {

  private bearerToken: string = '';
  private entityIdentifier: string = '';

  constructor(private http: HttpClient) {}

  public requestNewsEdgeArticle(articleID: string): Observable<NewsEdgeResponse> {
    let options = {
      headers: {'Authorization': 'Bearer '.concat(this.bearerToken)}
    };
    return this.http.get<NewsEdgeResponse>(environment.css_v3_api + environment.news_edge_article_endpoint + articleID, options).pipe(
      map(response => {
        return {
          data: {
            body: response.data.body,
            copyright_line: response.data.copyright_line
          }
        }
      })
    )
  }

  public requestScores(request: ScoresRequest): Observable<ScoreResponse>{
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.set('entityIdentifier', this.entityIdentifier);
      httpParams = httpParams.set('startDate', request.startDate.toISOString());
      httpParams = httpParams.set('endDate', request.endDate.toISOString());
      let options = {
        params: httpParams,
        headers: {'Authorization': 'Bearer '.concat(this.bearerToken)},
      };
  
      return this.http.get<ScoreResponse>(environment.css_v3_api + environment.scores_endpoint, options).pipe(
        map(response => {
          return {
            entityIdentifier: response.entityIdentifier,
            scores: response.scores.map(entry => {
              return {
                date: new Date(entry.date),
                score: entry.score
              }
            })
          }
        })
      );
  }

  public requestScoresAndEvents(request: ScoresAndEventsRequest): Observable<ScoreEventResponse> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('entityIdentifier', this.entityIdentifier);
    httpParams = httpParams.set('startDate', request.startDate.toISOString());
    httpParams = httpParams.set('endDate', request.endDate.toISOString());
    httpParams = httpParams.set('granularityLevel', request.granularityLevel.toString());
    let options = {
      params: httpParams,
      headers: {'Authorization': 'Bearer '.concat(this.bearerToken)},
    };
    return this.http.get<ScoreEventResponse>(environment.css_v3_api + environment.scores_and_events_endpoint, options)
    .pipe(
      map(response => {
        return {
          companyName: response.companyName,
          scoresAndEvents: response.scoresAndEvents.map(entry => {
            return {
              date: new Date(entry.date),
              score: entry.score,
              positiveEvents: entry.positiveEvents,
              negativeEvents: entry.negativeEvents,
              positiveCreditLabels: entry.positiveCreditLabels.map(label => {
                return {
                  creditLabel: label.creditLabel,
                  count: label.count
                }
              }),
              negativeCreditLabels: entry.negativeCreditLabels.map(label => {
                return {
                  creditLabel: label.creditLabel,
                  count: label.count
                }
              })
            }
          })
        }
      })
    );
  }

  public requestStories(request: StoriesRequest): Observable<NewsStoriesResponse> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('entityIdentifier', this.entityIdentifier);
    httpParams = httpParams.set('startDate', request.startDate.toISOString());
    httpParams = httpParams.set('endDate', request.endDate.toISOString());
    httpParams = httpParams.set('offset', request.offset);
    httpParams = httpParams.set('limit', request.limit);
    httpParams = httpParams.set('nonCreditRelevant', request.showNonCreditRelevant)
    request.creditFilter.forEach(label => {
      httpParams = httpParams.append('labelFilter', String(label));
    });
    request.eventFilter.forEach(label => {
      httpParams = httpParams.append('eventFilter', String(label));
    });
    let options = {
      headers: {'Authorization': 'Bearer '.concat(this.bearerToken)},
      params: httpParams
    };
    return this.http.get<NewsStoriesResponse>(environment.css_v3_api + environment.stories_endpoint, options).pipe(
      map(response => {
        return {
          entityIdentifier: response.entityIdentifier,
          storyCount: response.storyCount,
          stories: response.stories.map(story => {
            return {
              id: story.id,
              articleId: story.articleId,
              publicationDate: new Date(story.publicationDate),
              headline: he.decode(story.headline),
              organization: story.organization,
              summary: story.summary,
              source: story.source,
              articleCount: story.articleCount
            }
          }),
          filters: {
            creditLabelFilters: response.filters.creditLabelFilters.map(category => {
              return {
                count: category.count,
                creditLabel: category.creditLabel as CreditLabel
              }
            }),
            nonCreditRelevantCount: response.filters.nonCreditRelevantCount
          }
        }
      })
    );
  }

  public setToken(token: string){
    this.bearerToken = token;
  }

  public setEntity(entityIdentifier: string){
    this.entityIdentifier = entityIdentifier;
  }

}
