<app-base-card (refresh)="refreshComponent()" [title]="'Credit Sentiment Score'" [showRefresh]="networkError" [loading]="loading">
    <div class="sentiment-score-card-content-container">
        <app-custom-tooltip [tooltipText]="tooltip">
            <i *ngIf="!loading && !networkError"
                class="material-icons sentiment-score-card-information">info
            </i>
        </app-custom-tooltip>

        <div class="sentiment-score-card-score-container">
    
            <div class="sentiment-score-card-score" *ngIf="!noData">
                <div class="sentiment-score-card-score-actual">{{score}}</div>
                <div class="sentiment-score-card-score-potential">/100</div>
            </div>

            <div class="sentiment-score-card-score-actual" *ngIf="noData">
                N/A
            </div>
        
            <div [ngStyle]="{'color': this.getColorFromScore()}" class="sentiment-score-card-sentiment-rating">{{getSentimentText(score)}}</div>
        </div>
    
        <div class="sentiment-score-card-sentiment-container" *ngIf="!noData">
            <div [ngStyle]="{'left': indicatorPlacement() + 'px'}" class="sentiment-score-card-sentiment-indicator">▼</div>
            <div class="sentiment-score-card-sentiment-bar">
                <div class="sentiment-score-card-positive-bar">
                </div>
                <div class="sentiment-score-card-mixed-bar">
                </div>
                <div class="sentiment-score-card-trending-negative-bar">
                </div>
                <div class="sentiment-score-card-negative-bar">
                </div>
            </div>
        </div>
        <div class="sentiment-score-card-last-updated">{{getDateString()}}</div>
    </div>
</app-base-card>

<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
