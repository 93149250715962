<div class="base-card-container" [ngStyle]="applyStyle()">
    <div *ngIf="loading" class="base-card-spinner-container">
        <svg class="base-card-spinner" viewBox="0 0 50 50">   
            <circle class="base-card-path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
    </div>
    <div class="base-card-title">
        {{title}}
    </div>
    <div class="base-card-error-text" *ngIf="error && !showRefresh">{{errorText}}</div>
    <div class="base-card-refresh-icon-container" *ngIf="showRefresh && !loading" (click)="refresh.emit(true)">
        <span class="material-symbols-outlined base-card-refresh-icon">
            refresh
        </span>
    </div>
    <div [ngClass]="loading || error || showRefresh ? 'base-card-loading' : ''">
        <ng-content></ng-content>
    </div>
</div>

<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
