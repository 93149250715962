import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentimentScoreCardComponent } from './sentiment-score-card.component';
import { BaseCardModule } from '../base-card/base-card.module';
import { CustomTooltipModule } from '../custom-tooltip/custom-tooltip.module';

@NgModule({
    declarations: [
        SentimentScoreCardComponent
    ],
    imports: [
        CommonModule,
        BaseCardModule,
        CustomTooltipModule
    ],
    exports: [
        SentimentScoreCardComponent
    ]
})
export class SentimentScoreCardModule {}
