<div class="css-snippet-content" role="main">

    <div class="css-snippet-card-container">
        <app-sentiment-score-card #tokenDependent
            (toastMessage)="showToastMessage($event)"
            class="css-snippet-card">
        </app-sentiment-score-card>
        <app-events-count-card #tokenDependent
            (toastMessage)="showToastMessage($event)"
            class="css-snippet-card">
        </app-events-count-card>
    </div>
    
    <div class="css-snippet-chart-container">
        <app-chart-container-card #tokenDependent
            class="css-snippet-chart" 
            (toastMessage)="showToastMessage($event)">
        </app-chart-container-card>
    </div>
    <app-news-feed
        class="css-snippet-news-feed">
    </app-news-feed>
        
    <p-toast [life]="3000" [preventOpenDuplicates]="false"></p-toast>

</div>