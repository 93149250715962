import { Injectable } from '@angular/core';
import mergeImages from 'merge-images';
import { CharCommunicationData } from '../models/ChartCommunicationData';
import { Subject } from 'rxjs';

@Injectable()
export class ChartCommunicationService {

  private chartDownloadEvent: Subject<{image: string, companyName: string}> = new Subject();
  private updateChartDataEvent: Subject<CharCommunicationData> = new Subject();
  private chartZoomEvent: Subject<any> = new Subject();

  constructor() {}

  public listenForDownloadEvent() {
    return this.chartDownloadEvent.asObservable();
  }

  public pushDownloadEvent(svg: any){
    this.chartDownloadEvent.next(svg);
  }

  public listenForChartUpdateEvent() {
    return this.updateChartDataEvent.asObservable();
  }

  public pushChartUpdateEvent(data: CharCommunicationData) {
    this.updateChartDataEvent.next(data);
  }

  public listenForZoomEvent() {
    return this.chartZoomEvent.asObservable();
  }

  public pushZoomEvent(event: any) {
    this.chartZoomEvent.next(event);
  }

  public combineImages(svgTop: any, svgBottom: any){
    let topString = "data:image/svg+xml;base64," + btoa(svgTop.replace(/[\u00A0-\u2666]/g, function(c: any) { return '&#' + c.charCodeAt(0) + ';'; }));
    let bottomString = "data:image/svg+xml;base64," + btoa(svgBottom.replace(/[\u00A0-\u2666]/g, function(c: any) { return '&#' + c.charCodeAt(0) + ';'; }));

    return mergeImages([
      {
        src: topString,
        x: 0,
        y: 0
      },
      {
        src: bottomString,
        x: 0,
        y: 350
      }
    ], {height: 700})
  }

  public download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href',text);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
