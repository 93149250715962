<app-base-card [title]="'Credit Sentiment Score: '.concat(companyName)" (refresh)="dateSeriesClick(rangePreset)" [showRefresh]="networkError" [loading]="loading" contentType="chart">
    <div class="sentiment-score-chart-content-container">
        <div class="sentiment-score-chart-card-header">

            <div class="sentiment-score-chart-title-container">
                <div class="sentiment-score-chart-actions-container">
                    <app-custom-tooltip class="sentiment-score-chart-action-icon" [tooltipText]="'Download Image'">
                        <fa-icon (click)="exportPNG()" [icon]="faImage"/>
                    </app-custom-tooltip>
                    <app-custom-tooltip class="sentiment-score-chart-action-icon" [tooltipText]="'Download Chart CSV Data'">
                        <fa-icon (click)="exportCSV()" [icon]="faDownload"/>
                    </app-custom-tooltip>
                    
                </div>
            </div>
            <div class="sentiment-score-chart-options">
                <div class="sentiment-score-chart-series-date-container">
                    <div class="sentiment-score-chart-series-date-preset-container">
                        <div (click)="dateSeriesClick('3M')" [ngClass]="rangePreset == '3M' ? 'sentiment-score-chart-selected' : ''" class="sentiment-score-chart-series-date-preset-option">3M</div>
                        <div (click)="dateSeriesClick('6M')" [ngClass]="rangePreset == '6M' ? 'sentiment-score-chart-selected' : ''" class="sentiment-score-chart-series-date-preset-option">6M</div>
                        <div (click)="dateSeriesClick('1Y')" [ngClass]="rangePreset == '1Y' ? 'sentiment-score-chart-selected' : ''" class="sentiment-score-chart-series-date-preset-option">1Y</div>
                        <div (click)="dateSeriesClick('2Y')" [ngClass]="rangePreset == '2Y' ? 'sentiment-score-chart-selected' : ''" class="sentiment-score-chart-series-date-preset-option">2Y</div>
                    </div>
                    <mat-form-field class="sentiment-score-chart-series-date-input-form">
                        <mat-date-range-input (stateChanges)="dateSeriesClick('custom')" (keyup.enter)="dateSeriesClick('custom')" [rangePicker]="picker">
                            <input matStartDate [value]="queryStartDate" (dateChange)="queryStartDate=$event.value" placeholder="Start date">
                            <input matEndDate [value]="queryEndDate" (dateChange)="queryEndDate=$event.value;" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                        </mat-datepicker-toggle>
                        <mat-date-range-picker (closed)="dateSeriesClick('custom')" #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                
    
            </div>
            
        </div>
        <app-sentiment-score-chart (zoomEvent)="zoom($event)" [data]="scoreData">

        </app-sentiment-score-chart>
        <app-events-count-chart (zoomEvent)="zoom($event)" [data]="eventData">
            
        </app-events-count-chart>
    </div>
</app-base-card>