import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTooltipComponent } from './custom-tooltip.component';

@NgModule({
    declarations: [
        CustomTooltipComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CustomTooltipComponent
    ]
})
export class CustomTooltipModule {}
