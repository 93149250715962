import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleTileComponent } from './article-tile.component';
import { CustomTooltipModule } from '../custom-tooltip/custom-tooltip.module';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { StoryModalModule } from '../modals/story-modal/story-modal.module';

@NgModule({
    declarations: [
        ArticleTileComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        StoryModalModule,
        CustomTooltipModule
    ],
    exports: [
        ArticleTileComponent
    ]
})
export class ArticleTileModule {}
