import { Component, inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CSSModalData } from 'src/app/models/CSSModalData';
import { NewsEdgeResponse } from 'src/app/models/NewsEdgeResponse';
import { Sentiment } from 'src/app/models/Sentiment';
import { Story } from 'src/app/models/Story';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-story-modal',
  templateUrl: './story-modal.component.html',
  styleUrls: ['./story-modal.component.css']
})
export class StoryModalComponent {

  readonly dialogRef = inject(MatDialogRef<StoryModalComponent>);
  readonly dialogData: CSSModalData = inject(MAT_DIALOG_DATA);
  environment = environment;
  @Input() story!: Story;
  body: string = '';
  copyright: string = '';
  loading = false;
  error = true;

  showEvents: boolean = false;

  private positiveTagStyle = {
    borderColor: 'rgb(99, 148, 67)',
    background: 'rgba(131,181,98,.15)',
    color: 'rgb(99, 148, 67)'
  }

  private negativeTagStyle = {
    borderColor: 'rgb(210, 70, 70)',
    background: 'rgba(210, 70, 70, .15)',
    color: 'rgb(210, 70, 70)'
  }

  private neutralTagStyle = {
    border: 'none',
    padding: '4px 12px',
    background: '#EEEEEE',
    color: '#666666'
  }

  ngOnInit(){
    this.requestArticleBody();
  }

  requestArticleBody() {
    this.loading = true;
    this.error = false;
    this.dialogData.requestNewsEdgeArticle(this.story.articleId).subscribe({
      next: (response: NewsEdgeResponse) => {
        this.body = response.data.body;
        this.copyright = response.data.copyright_line
      },
      error: (error) => {
        this.error = true;
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
        this.error = false;
      }
    })
  }

  tryAgain(){
    this.requestArticleBody();
  }

  formatDate(date: Date) {
    return date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'})
  }

  applyTagStyle(sentiment: Sentiment){
    switch(sentiment){
      case Sentiment.POSITIVE : return this.positiveTagStyle;
      case Sentiment.NEUTRAL : return this.neutralTagStyle;
      case Sentiment.NEGATIVE : return this.negativeTagStyle;
    }
  }
}
