import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GranularityLevel } from '../../models/GranularityLevel';
import { ScoreEventEntry } from '../../models/ScoreEventEntry';

import * as Highcharts from 'highcharts'


@Component({
  selector: 'app-events-count-chart',
  templateUrl: './events-count-chart.component.html',
  styleUrls: ['./events-count-chart.component.css']
})
export class EventsCountChartComponent {

  @Input() data: Array<ScoreEventEntry> = [];
  @Output() zoomEvent: EventEmitter<any> = new EventEmitter;
  grouping: GranularityLevel = GranularityLevel.DAY;

  Highcharts: typeof Highcharts = Highcharts;
  chartRef!: Highcharts.Chart;
  chartOptions: Highcharts.Options = {};
  chartTitle = 'Event Count'

  ngOnChanges(){
    this.chartOptions = this.createChart();
    this.chartRef = Highcharts.charts.find(chartChild => {
      return chartChild?.options.chart!.type == 'column'
    })!;
  }

  public getPNG() {
    return this.chartRef.getSVG(
      {
        chart: {
          backgroundColor: 'white',
          height: 350
        },
        caption: {
          text: `@ Copyright ${new Date().getFullYear()} Moody\'s, Inc. and/or its licensors and affiliates. All rights reserved.`
        }
      }
    )
  }

  getTickPositions(): Array<number> {
    if (this.data.length > 0) {
      let chunk: Array<number> = []; 
      let resultMap: Array<Array<number>> = []
      let tick = this.getDateProperty(this.data[0].date);
      this.data.forEach((entry, index) => {
        if(this.getDateProperty(entry.date) != tick) {
          resultMap.push(chunk);
          chunk = [index];
          tick = this.getDateProperty(entry.date);
        }
        else {
          chunk.push(index);
        }
        if(index == this.data.length-1){
          resultMap.push(chunk);
        }
      });
      return resultMap.map(entry => entry[0])
    }
    return []
  }

  private getDateProperty(date: Date) {
    switch(this.grouping) {
      case GranularityLevel.DAY :
        return date.getUTCMonth();
      case GranularityLevel.WEEK :
        return date.getUTCMonth();
      case GranularityLevel.MONTH :
        return date.getUTCFullYear();
    }
  }

  private getCategories() {
    switch(this.grouping) {
      case GranularityLevel.DAY :
        return this.data.map(entry => entry.date.toLocaleDateString('en-us', {month: 'short', year: 'numeric', timeZone: 'UTC'}))
      case GranularityLevel.WEEK :
        return this.data.map(entry => entry.date.toLocaleDateString('en-us', {month: 'short', year: 'numeric', timeZone: 'UTC'}))
      case GranularityLevel.MONTH :
        return this.data.map(entry => entry.date.toLocaleDateString('en-us', {year: 'numeric', timeZone: 'UTC'}))
    }
  }
  private formatDate(date: Date, grouping: GranularityLevel) {
    switch(grouping) {
      case GranularityLevel.DAY :
        return date.toLocaleDateString('en-us', {day: 'numeric', month: 'short', year: 'numeric', timeZone: 'UTC'})
      case GranularityLevel.WEEK : 
        return 'Week Starting '.concat(date.toLocaleDateString('en-us', {month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC'}));
      case GranularityLevel.MONTH :
        return date.toLocaleDateString('en-us', {month: 'long', year: 'numeric', timeZone: 'UTC'})
    }
  }

  private getColorFromScore(score: number) {
    if(score <= 30) return 'rgb(131, 181, 98)';
    if(score <= 50) return 'rgb(196, 177, 2)';
    if(score <= 70) return  '#ff8800';
    else return "rgb(210, 70, 70)"
  }

  private createChart() {
    let options: Highcharts.Options;
    let data = this.data;
    let getColorFromScore = this.getColorFromScore;
    let formatDate = this.formatDate;
    let grouping = this.grouping;
    options = {
      chart: {
        backgroundColor: undefined,
        type: 'column',
        events: {
          selection: (event) => {
            this.zoomEvent.emit({
              queryStartDate: this.data[Math.floor(event.xAxis[0].min)].date,
              queryEndDate: this.data[Math.floor(event.xAxis[0].max)].date
            });
            return false;
          }
        },
        zooming: {
          type: 'x'
        }
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false
      },
      xAxis: {
        tickPositions: this.getTickPositions(),
        categories: this.getCategories(),
        crosshair: true,
        labels: {
          useHTML: true,
          allowOverlap: false,
          rotation: -45,
          distance: 30,
          style: {
            whiteSpace: "nowrap",
            textOverflow: "none",
          },
        },
        tickLength: 10,
        tickWidth: 2,
        gridLineWidth: 1,
        lineWidth: 1,
        tickmarkPlacement: 'on'
      },
      yAxis: {
        title: {
          text: this.chartTitle
        },
        // height: 250
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          maxPointWidth: 20,
          minPointLength: 0,
          borderRadius: 4,
          borderWidth: 0
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: 'column',
          color: 'rgb(210, 70, 70)',
          name: 'Negative Events',
          data: this.data.map(entry => [this.formatDate(entry.date, this.grouping), entry.negativeEvents])
        },
        {
          type: 'column',
          color: 'rgb(131, 181, 98)',
          name: 'Positive Events',
          data: this.data.map(entry => [this.formatDate(entry.date, this.grouping), entry.positiveEvents])
        }
      ],
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function() {
          let associatedEventEntry = data[this.series.data.indexOf(this.point)];
          let positive = this.series.getName() === 'Positive Events';
          let eventCount = positive ? associatedEventEntry.positiveEvents : associatedEventEntry.negativeEvents;
          return `
            <div style="display: flex; align-items: flex-end; justify-content: space-between; padding-bottom: 10px">
              <div style="font-weight: bold">
                Score:
              </div>
              <div style="display: flex; justify-content: center; align-items: center; color: white; background-color: ${getColorFromScore(associatedEventEntry.score)}; border-radius: 5px; height: 30px; width: 30px">
                ${associatedEventEntry.score} 
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; padding-bottom: 10px; width: 200px">
              <a style="display: flex; justify-content: center; align-items: center">
                ${positive ? 'Positive Events' : 'Negative Events'}
              </a>
              <a style="display: flex; justify-content: center; align-items: center; width: 30px">
                ${eventCount}
              </a>
            </div>
            <div style="color: rgb(100,100,100); padding-top: 20px">${formatDate(associatedEventEntry.date, grouping)}</div>
          `;
        }
      }
    }
    return options;
  }

}
