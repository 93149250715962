import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsFeedComponent } from './news-feed.component';
import { NewsFeedSidebarModule } from '../news-feed-sidebar/news-feed-siderbar.module';
import { ArticleTileModule } from '../article-tile/article-tile.module';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
    declarations: [
        NewsFeedComponent
    ],
    imports: [
        CommonModule,
        NewsFeedSidebarModule,
        ArticleTileModule,
        MatPaginatorModule
    ],
    exports: [
        NewsFeedComponent
    ]
})
export class NewsFeedModule {}
