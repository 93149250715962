import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoryModalComponent } from './story-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CreditSentimentAPIService } from 'src/app/services/css-api-service.service';

@NgModule({
    declarations: [
        StoryModalComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule
    ],
    exports: [
        StoryModalComponent
    ]
})
export class StoryModalModule {}
