
<div class="article-container" *ngIf="story">
    <a class="article-header" onclick="event.stopPropagation()" [href]="environment.newsEdgeURL.concat(story.articleId)" target="_blank">{{story.headline}}</a>
    <div class="article-info">
        <div class="article-source-container">
            <div class="article-source">{{story.source}}</div>
            <div class="article-source-separator"></div>
            <div class="article-date">Retrieved: {{story.publicationDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'})}}</div>
        </div>
        <div class="article-count" *ngIf="story.articleCount > 1">+{{story.articleCount - 1}} related articles</div>
    </div>
    <div class="article-summary">
        {{formatSummary(story.summary)}}
        <div (click)="openDialog(story)" class="article-read-more">Read More...</div>
    </div>
    <div class="article-tags-container" [ngStyle]="tagsContainerStyle" [id]="story.id.concat('_article_container')">
        <ng-template ngFor let-tag [ngForOf]="story.organization.derivedTags" let-i="index">
            <app-custom-tooltip [tooltipText]="tag.eventDescription" [tooltipId]='story.id.concat(i.toString())'>
                <div [ngStyle]="applyTagStyle(tag.sentiment)" class="article-tag">{{tag.event}}</div>
            </app-custom-tooltip>
        </ng-template>
    </div>
    <div class="expand-tags" onclick="event.stopPropagation()" (click)="expandTags()" *ngIf="showMore">{{expandText}}</div>
</div>