import { Component, EventEmitter, Input, Output} from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FilterOptions } from 'src/app/models/FilterOptions';
import { NewsFilterCategory } from 'src/app/models/NewsFilterCategory';
import { NewsFilters } from 'src/app/models/NewsFilters';
import { Sentiment } from 'src/app/models/Sentiment';


@Component({
  selector: 'app-news-feed-sidebar',
  templateUrl: './news-feed-sidebar.component.html',
  styleUrls: ['./news-feed-sidebar.component.css']
})
export class NewsFeedSidebarComponent {

  magnifyingIcon = faMagnifyingGlass;

  @Input() categories!: FilterOptions
  @Input() resetFilters: boolean = true;
  @Input() loading: boolean = true;
  @Output() filter: EventEmitter<NewsFilters> = new EventEmitter();

  categoryCheckbox: Array<{checked: boolean, category: NewsFilterCategory}> = [];

  positiveEventsSelected: boolean = true;
  negativeEventsSelected: boolean = true;
  nonCreditRelevantSelected: boolean = true;

  ngOnChanges(){
    if(this.resetFilters || this.categoryCheckbox.length == 0){
      this.nonCreditRelevantSelected = true;
      this.categoryCheckbox = this.categories.creditLabelFilters.map(category => {
        return {checked: true, category: category}
      });
    }
  }

  updateFilters(){
    let eventsCheckbox = [
      {
        checked: this.positiveEventsSelected,
        sentiment: Sentiment.POSITIVE
      },
      {
        checked: this.negativeEventsSelected,
        sentiment: Sentiment.NEGATIVE
      }
    ]
    this.filter.emit({
      showNonCreditRelevant: this.nonCreditRelevantSelected,
      events: eventsCheckbox.filter(event => event.checked).map(checkbox => checkbox.sentiment),
      categories: this.categoryCheckbox.filter(category => category.checked).map(categoryCheckbox => categoryCheckbox.category.creditLabel)})
  }

  resetAllFilters(){
    this.positiveEventsSelected = true;
    this.negativeEventsSelected = true;
    this.resetCategoryFilters(true);
  }

  resetEventFilters(value: boolean){
    this.positiveEventsSelected = value;
    this.negativeEventsSelected = value;

    this.updateFilters();
  }

  resetCategoryFilters(value: boolean){
    this.nonCreditRelevantSelected = value
    this.categoryCheckbox.forEach(category => {
      category.checked = value;
    })
    this.updateFilters();
  }

}
