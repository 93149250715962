import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { CssUiContainerModule } from './components/css-ui-container/css-ui-container.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CssUiContainerModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
