import { Component } from '@angular/core';
import { NewsFilters } from 'src/app/models/NewsFilters';
import { PageEvent } from '@angular/material/paginator';
import { CreditSentimentAPIService } from 'src/app/services/css-api-service.service';
import { NewsStoriesResponse } from 'src/app/models/NewsStoriesResponse';
import { ChartCommunicationService } from 'src/app/services/chart-communication-service';
import { CreditLabel } from 'src/app/models/CreditLabel';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent {

  startDate: Date = new Date();
  endDate: Date = new Date();
  totalEventCount: number = 0;
  loading: boolean = false;

  storiesResponse: NewsStoriesResponse = {entityIdentifier: '', storyCount: 0, stories: [], filters: {creditLabelFilters: [], nonCreditRelevantCount: 0}};

  articleCount = 0;
  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  resetFilters: boolean = true;

  private activeFilters: NewsFilters = {
    showNonCreditRelevant: true,
    events: [],
    categories: []
  }
  
  constructor(private cssService: CreditSentimentAPIService, private chartCommunicationService: ChartCommunicationService){
  }

  ngOnInit() {
    let requestDate = new Date();
    requestDate.setDate(new Date().getDate() - 7);
    this.chartCommunicationService.listenForChartUpdateEvent().subscribe(chartUpdateEvent => {
      if(chartUpdateEvent.loading){
        this.startDate = chartUpdateEvent.startDate;
        this.endDate = chartUpdateEvent.endDate;
        this.reset();
        this.requestStories();
      } else if(chartUpdateEvent.error){
        this.reset();
      }
    });
  }

  filter(filters: NewsFilters){
    this.activeFilters = filters;
    this.pageIndex = 0;
    if(((filters.categories.length === 0 && !filters.showNonCreditRelevant) || 
      filters.events.length == 0) ){
      this.storiesResponse.stories = [];
    } else {
      this.requestStories();
    }
  }

  requestStories(){
    this.loading = true;
    this.cssService.requestStories({
      entityIdentifier: '',
      creditFilter: this.activeFilters.categories,
      eventFilter: this.activeFilters.events,
      offset: this.pageSize * this.pageIndex,
      limit: this.pageSize,
      startDate: this.startDate,
      endDate: this.endDate,
      showNonCreditRelevant: this.activeFilters.showNonCreditRelevant
    }).subscribe({
      next: result => {
        this.loading = false;
        this.storiesResponse = result;
        this.articleCount = result.storyCount;
        this.resetFilters = false;
    },
    error: error => {
      this.loading = false;
      this.reset();
    }
    });
  }

  reset(){
    this.resetFilters = true;
    this.activeFilters.categories = Object.values(CreditLabel).map(label => label as CreditLabel);
    this.activeFilters.events = [];
    this.activeFilters.showNonCreditRelevant = true;
    this.storiesResponse.filters = {
      nonCreditRelevantCount: 0,
      creditLabelFilters: []
    };
    this.storiesResponse.stories = [];
    this.pageIndex = 0;
  }

  handlePageEvent(event: PageEvent){
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.requestStories();
  }

}
