import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { CreditSentimentAPIService } from 'src/app/services/css-api-service.service';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api/message';
import { ChartCommunicationService } from 'src/app/services/chart-communication-service';


@Component({
  selector: 'app-css-ui-container',
  templateUrl: './css-ui-container.component.html',
  providers: [
    CreditSentimentAPIService,
    ChartCommunicationService,
    MessageService
  ],
  styleUrls: [
    './css-ui-container.component.css',
  ],
})
export class CssUiContainerComponent {

  @ViewChildren('tokenDependent') children!: QueryList<any>;

  @Input() 
  entityIdentifier: string = '';

  @Input()
  bearerToken: string = '';

  viewInitted: boolean = false;

  constructor(private scoreService: CreditSentimentAPIService, private messageService: MessageService){}

  ngOnChanges() {
    if(this.viewInitted){
      this.updateComponentsAndServices();
    }
  }

  ngAfterViewInit() {
    this.viewInitted = true;
    this.updateComponentsAndServices();
  }

  updateComponentsAndServices(){
    this.scoreService.setToken(this.bearerToken);
    this.scoreService.setEntity(this.entityIdentifier);
    if(this.children && this.entityIdentifier  && this.bearerToken){
      this.children.forEach(child => {
        child.refreshComponent();
      })
    }
  }

  showToastMessage(message: Message) {
    this.messageService.add(message);
  }
}
