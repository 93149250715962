import * as Highcharts from 'highcharts';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentimentScoreChartComponent } from './sentiment-score-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';

const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)


@NgModule({
    declarations: [
        SentimentScoreChartComponent
    ],
    imports: [
        CommonModule,
        HighchartsChartModule
    ],
    exports: [
        SentimentScoreChartComponent
    ]
})
export class SentimentScoreChartModule {}
