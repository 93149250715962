<app-base-card [title]="'Events Count'" (refresh)="refreshComponent()" [showRefresh]="networkError" [loading]="loading">
    <div class="events-count-card-content-container">
        <div class="events-count-card-chart-content-container">
                <highcharts-chart class="events-count-card-chart"
                    [Highcharts]="Highcharts"
                    [options]="chartOptions"
                ></highcharts-chart>
            <div class="events-count-card-chart-key-container">
                <div class="events-count-card-chart-key">
                    <fa-icon class="events-count-card-arrow-icon" style="color : rgb(131,181,98)" [icon]="faArrowTrendUp"></fa-icon>
                    <p class="events-count-card-tag-description">Positive</p>
                    <div class="events-count-card-tag-count">{{positiveTags}}</div>
                </div>
                <div class="events-count-card-chart-key">
                    <fa-icon class="events-count-card-arrow-icon" style="color : rgb(210, 70, 70)" [icon]="faArrowTrendDown"></fa-icon>
                    <p class="events-count-card-tag-description">Negative</p>
                    <div class="events-count-card-tag-count">{{negativeTags}}</div>
                </div>
            </div>
        </div>
        <div class="events-count-card-last-updated">{{getDateString()}}</div>
    </div>
</app-base-card>