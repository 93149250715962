import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { faDownload, faImage } from '@fortawesome/free-solid-svg-icons';
import { Message } from 'primeng/api/message';
import { forkJoin } from 'rxjs';
import { GranularityLevel } from 'src/app/models/GranularityLevel';
import { ScoreEntry } from 'src/app/models/ScoreEntry';
import { ScoreEventEntry } from 'src/app/models/ScoreEventEntry';
import { ChartCommunicationService } from 'src/app/services/chart-communication-service';
import { CreditSentimentAPIService } from 'src/app/services/css-api-service.service';
import { SentimentScoreChartComponent } from '../sentiment-score-chart/sentiment-score-chart.component';
import { EventsCountChartComponent } from '../events-count-chart/events-count-chart.component';

@Component({
  selector: 'app-chart-container-card',
  templateUrl: './chart-container-card.component.html',
  styleUrls: ['./chart-container-card.component.css']
})
export class ChartContainerCardComponent {

  @ViewChild(SentimentScoreChartComponent) scoreChart!: SentimentScoreChartComponent;
  @ViewChild(EventsCountChartComponent) eventChart!: EventsCountChartComponent;
  
  @Output() toastMessage: EventEmitter<Message> = new EventEmitter;

  eventData: Array<ScoreEventEntry> = [];
  scoreData: Array<ScoreEntry> = [];
  companyName: string = '';
  loading: boolean = false;
  networkError: boolean = true;

  faImage = faImage;
  faDownload = faDownload;

  queryStartDate: Date;
  queryEndDate: Date;
  rangePreset: '3M' | '6M' | '1Y' | '2Y' | '3Y' | '5Y' | undefined = '1Y';

  constructor(private cssService: CreditSentimentAPIService, private chartCommunicationService: ChartCommunicationService) {

    this.queryEndDate = new Date();
    this.queryStartDate = new Date(this.queryEndDate);
    this.queryStartDate.setFullYear(this.queryEndDate.getFullYear() -1);

    this.chartCommunicationService.listenForZoomEvent().subscribe((event) => {
      this.queryStartDate = event.startDate
      this.queryEndDate = event.endDate
      this.dateSeriesClick('custom');
    });
  }

  refreshComponent(){
    this.dateSeriesClick('1Y');
  }

  dateSeriesClick(rangePreset: any){

    switch(rangePreset){
      case '3M' :

        this.queryEndDate = new Date();
        this.queryStartDate = new Date(this.queryEndDate);
        this.queryStartDate.setMonth(this.queryEndDate.getMonth() - 3);
        break;

      case '6M' :

        this.queryEndDate = new Date();
        this.queryStartDate = new Date(this.queryEndDate);
        this.queryStartDate.setMonth(this.queryEndDate.getMonth() - 6);
        break;

      case '1Y' :

        this.queryEndDate = new Date();
        this.queryStartDate = new Date(this.queryEndDate);
        this.queryStartDate.setFullYear(this.queryEndDate.getFullYear() - 1);
        break;

      case '2Y' :

        this.queryEndDate = new Date();
        this.queryStartDate = new Date(this.queryEndDate);
        this.queryStartDate.setFullYear(this.queryEndDate.getFullYear() - 2);
        break;
    }

    this.getDataFromAPI();

    this.rangePreset = rangePreset;
  }

  private getDataFromAPI(){

    this.loading = true;
    this.networkError = false;

    forkJoin([
      this.cssService.requestScores({
        startDate: new Date(this.queryStartDate),
        endDate: new Date(this.queryEndDate),
        entityIdentifier: ''
      }),
      this.cssService.requestScoresAndEvents({
        startDate: new Date(this.queryStartDate),
        endDate: new Date(this.queryEndDate),
        entityIdentifier: '',
        granularityLevel: GranularityLevel.DAY
      })
    ]).subscribe({
      next: (value) => {
        this.scoreData = value[0].scores
        this.eventData = value[1].scoresAndEvents;
        this.companyName = value[1].companyName;
        this.sendChartUpdateEvent();
        this.loading = false;
        this.networkError = false;
      },
      error: (err) => {
        this.loading = false
        this.networkError = true;
        this.toastMessage.emit({ severity: 'error', summary: 'Error', detail: 'Network Error: Please contact network administrator' });
      }
    })
  }

  exportCSV(){
    if(this.eventData && this.eventData.length > 0){
      let csv: string = [
        [
          'Date',
          'Score',
          'Positive Events',
          'Negative Events'
        ],
        ...this.eventData.map(entry => [
          [
            entry.date.getUTCFullYear(),
            ('0' + (entry.date.getUTCMonth() + 1)).slice(-2),
            ('0' + entry.date.getUTCDate()).slice(-2)
          ].join('-'),
          entry.score,
          entry.positiveEvents,
          entry.negativeEvents
        ])
      ].map(e => e.join(','))
      .join("\n");

      csv += '\n\n"© "';
      csv += `@ Copyright ${new Date().getFullYear()} Moody\'s, Inc. and/or its licensors and affiliates. All rights reserved.`
      csv = '\uFEFF' + csv;

      this.chartCommunicationService.download(`${this.companyName} CSS Data.csv`, 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    } else {
      this.toastMessage.emit({severity: 'error', summary: 'Error', detail: 'no data available for company and date range'});
    }
  }

  private sendChartUpdateEvent(){
    this.chartCommunicationService.pushChartUpdateEvent({
      entityIdentifier: '',
      grouping: GranularityLevel.DAY,
      startDate: new Date(this.queryStartDate),
      endDate: new Date(this.queryEndDate),
      loading: this.loading,
      error: this.networkError
    });
  }

  exportPNG() {
    let scoreChartPng = this.scoreChart.getPNG(this.companyName, this.queryStartDate, this.queryEndDate);
    let eventChartPng = this.eventChart.getPNG();

    this.chartCommunicationService.combineImages(scoreChartPng, eventChartPng).then(img => {
      this.chartCommunicationService.download(`${this.companyName} CSS.png`, img);
    })
  }

  zoom(event: {queryStartDate: Date, queryEndDate: Date}) {
    this.queryStartDate = event.queryStartDate
    this.queryEndDate = event.queryEndDate
    this.dateSeriesClick('custom');
  }

}
