import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.css']
})
export class CustomTooltipComponent {

  @Input()
  tooltipId = 'css-custom-tooltip';

  @Input()
  tooltipText = 'placeholder tooltip';

  showTooltip(e: MouseEvent){
    let tooltip = document.createElement('span');
    tooltip.id = this.tooltipId;
    tooltip.innerHTML = this.tooltipText;
    tooltip.style.position = 'absolute';
    tooltip.style.maxWidth = '300px';
    tooltip.style.width = 'fit-content';
    tooltip.style.top = e.clientY - 100 - e.offsetY + 'px';
    tooltip.style.left = e.clientX - 150 + e.offsetX + 'px';
    tooltip.style.display = 'flex';
    tooltip.style.overflow ='auto';
    tooltip.style.justifyContent = 'center';
    tooltip.style.wordWrap = 'normal';
    tooltip.style.background = 'white';
    tooltip.style.whiteSpace = 'pre-line';
    tooltip.style.fontSize = '12px';
    tooltip.style.padding = '10px';
    tooltip.style.lineHeight = '20px';
    tooltip.style.border = 'solid';
    tooltip.style.borderColor = '#ababab';
    tooltip.style.borderRadius = '5px';
    tooltip.style.zIndex = '2';
    document.body.appendChild(tooltip);
  }

  moveTooltip(e: MouseEvent) {
    let tooltip = document.getElementById(this.tooltipId);
    if(tooltip){
      let width = tooltip.getBoundingClientRect().width;
      let height = tooltip.getBoundingClientRect().height;
  
      if(e.clientY - (height+20) < 10){ 
        tooltip.style.top = e.pageY + 20 + 'px';
      } else{
        tooltip.style.top = e.pageY - height - 20 + 'px';
      }
        tooltip.style.left = e.pageX - (width/2) + 'px';
    }
  }

  hideTooltip() {
    let el = document.getElementById(this.tooltipId);
    if(el){
      document.body.removeChild(el);
    }
  }

}
