import * as Highcharts from 'highcharts';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsVolumeChartComponent } from './news-volume-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';

const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)

@NgModule({
    declarations: [
        NewsVolumeChartComponent
    ],
    imports: [
        CommonModule,
        HighchartsChartModule
    ],
    exports: [
        NewsVolumeChartComponent
    ]
})
export class NewsVolumeChartModule {}
