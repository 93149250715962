import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { CssUiContainerComponent } from './css-ui-container.component';
import { CommonModule } from '@angular/common';
import { EventsCountCardModule } from '../events-count-card/events-count-card.module';
import { SentimentScoreCardModule } from '../sentiment-score-card/sentiment-score-card.module';
import { NewsFeedModule } from '../news-feed/news-feed.module';
import { ChartContainerCardModule } from '../chart-container-card/chart-container-card.module';
import { ToastModule } from 'primeng/toast';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    CssUiContainerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    EventsCountCardModule,
    SentimentScoreCardModule,
    NewsFeedModule,
    ChartContainerCardModule,
    HttpClientModule,
    ToastModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    },
  ],
  exports: [
    CssUiContainerComponent
  ]
})
export class CssUiContainerModule { 
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const el = createCustomElement(CssUiContainerComponent, { injector: this.injector });
    customElements.define('css-snippet', el);
  }
}
