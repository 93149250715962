import { Component, EventEmitter, Output } from '@angular/core';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import { faArrowTrendDown } from '@fortawesome/free-solid-svg-icons';
import * as Highcharts from 'highcharts'
import { GranularityLevel } from 'src/app/models/GranularityLevel';
import { ScoreEventEntry } from 'src/app/models/ScoreEventEntry';
import { CreditSentimentAPIService } from 'src/app/services/css-api-service.service';
import { Message } from 'primeng/api/message';
import { ScoreEventResponse } from 'src/app/models/ScoreEventResponse';

@Component({
  selector: 'app-events-count-card',
  templateUrl: './events-count-card.component.html',
  styleUrls: ['./events-count-card.component.css']
})
export class EventsCountCardComponent {

  faArrowTrendUp = faArrowTrendUp;
  faArrowTrendDown = faArrowTrendDown;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options = {};

  @Output() toastMessage: EventEmitter<Message> = new EventEmitter;


  date: Date = new Date();
  positiveTags: number = 0;
  negativeTags: number = 0;
  loading: boolean = false;
  networkError: boolean = true;

  constructor(private creditSentimentAPIService: CreditSentimentAPIService){}

  refreshComponent(){
    this.positiveTags = 0;
    this.negativeTags = 0;
    this.loading = true;

    let reqStartDate = new Date();
    reqStartDate.setDate(-7);

    this.creditSentimentAPIService.requestScoresAndEvents({
      entityIdentifier: '',
      startDate: reqStartDate,
      endDate: new Date(),
      granularityLevel: GranularityLevel.DAY
    }).subscribe({
      next: (result: ScoreEventResponse) => {
        let scoreAndEvent = result.scoresAndEvents.length > 0 ? result.scoresAndEvents.pop()! : {score: 0, date: new Date()}
        result.scoresAndEvents.forEach((entry: ScoreEventEntry) => {
          this.positiveTags += entry.positiveEvents;
          this.negativeTags += entry.negativeEvents;
        });
        this.date = scoreAndEvent.date;
        this.loading = false;
        this.networkError = false;

        this.chartOptions = this.generateChart();
      },
      error: (error) => {
        this.loading = false
        this.networkError = true;
        this.toastMessage.emit({ severity: 'error', summary: 'Error', detail: 'Network Error: Please contact network administrator' })

        this.chartOptions = this.generateChart();
      }
    });
  }

  ngOnInit(){
    this.chartOptions = this.generateChart();
  }

  getDateString() {
    return "Last 30 days as of " + this.date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'})
  }

  generateChart(){
    let options: Highcharts.Options;
    options = {
      colors: ['rgb(131, 181, 98)', 'rgb(210, 70, 70)'],
      chart: {
        type: 'pie',
        backgroundColor: undefined,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0,
        plotBorderColor: 'rgb(0,0,0)',
        margin: [0,0,0,0],
      },
      exporting: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        outside: true,
        valueSuffix: '%'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          startAngle: 330,
          size: '80px',
          borderColor: 'rgb(243, 242, 239)',
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '{point.name}: {y} %'
          },
          showInLegend: false
        }
      },
      credits: { enabled: false },
      series: [{
        type: 'pie',
        borderRadius: '6px',
        name: 'Percentage',
        innerSize: '72%',
        data: [{
          name: 'Positive',
          y: this.positiveTags == 0 && this.negativeTags == 0 ? 50 : Math.round((this.positiveTags / (this.negativeTags + this.positiveTags)) * 100)
        }, {
          name: 'Negative',
          y: this.positiveTags == 0 && this.negativeTags == 0 ? 50 : Math.round((this.negativeTags / (this.negativeTags + this.positiveTags)) * 100)
        }],
        states: {
          hover: {
            halo: null
          }
        }
      }]
    };
    return options;
  } 
}