import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsCountCardComponent } from './events-count-card.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BaseCardModule } from '../base-card/base-card.module';

@NgModule({
    declarations: [
        EventsCountCardComponent
    ],
    imports: [
        CommonModule,
        BaseCardModule,
        HighchartsChartModule,
        FontAwesomeModule,
    ],
    exports: [
        EventsCountCardComponent
    ]
})
export class EventsCountCardModule {}
