<div class="input-container">
  <div class="input-box-container">
    <label class="control-label">Entity Identifier:</label>
    <input type="text" class="form-control" id="entityIdInput" [(ngModel)]="entityIdentifier"/>
  </div>

  <div class="input-box-container">
    <label class="control-label">Bearer Token:</label>
    <input type="text" class="form-control" id="bearerTokenInput" [(ngModel)]="bearerToken">
  </div>
</div>


<app-css-ui-container 
  [entityIdentifier]="entityIdentifier" 
  [bearerToken]="bearerToken">
</app-css-ui-container>
